.avatar {
  z-index: 10;
  height: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
}

.avatar img {
  z-index: 1;
  border-radius: 50%;
}

.avatar.sm {
  width: 25px;
}

.avatar.md {
  width: 42px;
}

.avatar.lg {
  width: 60px;
}

.avatar.auto {
  width: auto;
}

.avatar[class*='marker--'] {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
}

.avatar.selected,
.avatar.hoverable:hover {
  border: 2px solid var(--color-white);
}

.avatar.selected:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  border-radius: 50%;
  z-index: 1;
}

.avatar > svg {
  position: absolute;
  z-index: 2;
}

.avatar.marker--ai {
  border: 2px solid var(--color-white);
  width: 22px;
  z-index: 50;
}

.avatar.marker--user {
  border: 3px solid var(--color-white);
  width: 42px;
  z-index: 100;
}

.avatar[class*='marker--']::after {
  background: url('../../assets/svg/caret.svg') center center no-repeat;
  content: '';
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 100%;
}

.avatar.marker--user::after {
  top: 32px;
}

.avatar .crown {
  height: 10px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -7px;
  width: 14px;
  z-index: 75;
}

.avatar.sm .crown {
  top: -14px;
}
