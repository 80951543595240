:root {
  --mobile-breakpoint: 875px;
  --border-radius: 6px;

  /* Custom colors */
  --color-with-ivs: #2b313f;
  --color-light-background: #454545;
  --color-lightest-background: #d4d4d4;
  --color-dark-background: #2d2d2d;
  --color-darkest-background: #1f1f1f;

  --color-black: #000;
  --color-near-black: #0f1112;

  --color-dark-gray: #33393c;
  --color-mid-gray: #4b5358;
  --color-gray: #8d9ca7;

  --color-silver: #999;
  --color-light-silver: #a2b4c0;

  --color-moon-gray: #dfe5e9;
  --color-light-gray: #e7ecf0;
  --color-near-white: #f1f2f3;
  --color-white: #fff;

  --color-dark-red: #e7040f;
  --color-red: #fd2222;
  --color-light-red: #ff725c;

  --color-orange: #ff6300;
  --color-gold: #ffb700;
  --color-yellow: #ffd700;
  --color-light-yellow: #fbf1a9;

  --color-purple: #5e2ca5;
  --color-light-purple: #a463f2;

  --color-dark-pink: #d5008f;
  --color-hot-pink: #ff41b4;
  --color-pink: #ff80cc;
  --color-light-pink: #ffa3d7;

  --color-dark-green: #137752;
  --color-green: #0fd70b;
  --color-light-green: #9eebcf;

  --color-navy: #001b44;
  --color-dark-blue: #2026a2;
  --color-blue: #2b44ff;
  --color-light-blue: #8bb0ff;
  --color-lightest-blue: #e0eaff;

  --color-washed-blue: #f6fffe;
  --color-washed-green: #e8fdf5;
  --color-washed-yellow: #fffceb;
  --color-washed-red: #ffdfdf;

  --color-white-90: rgba(255, 255, 255, 0.9);
  --color-white-80: rgba(255, 255, 255, 0.8);
  --color-white-70: rgba(255, 255, 255, 0.7);
  --color-white-60: rgba(255, 255, 255, 0.6);
  --color-white-50: rgba(255, 255, 255, 0.5);
  --color-white-40: rgba(255, 255, 255, 0.4);
  --color-white-30: rgba(255, 255, 255, 0.3);
  --color-white-20: rgba(255, 255, 255, 0.2);
  --color-white-10: rgba(255, 255, 255, 0.1);
  --color-white-05: rgba(255, 255, 255, 0.05);

  --color-black-90: rgba(0, 0, 0, 0.9);
  --color-black-80: rgba(0, 0, 0, 0.8);
  --color-black-70: rgba(0, 0, 0, 0.7);
  --color-black-60: rgba(0, 0, 0, 0.6);
  --color-black-50: rgba(0, 0, 0, 0.5);
  --color-black-40: rgba(0, 0, 0, 0.4);
  --color-black-30: rgba(0, 0, 0, 0.3);
  --color-black-20: rgba(0, 0, 0, 0.2);
  --color-black-10: rgba(0, 0, 0, 0.1);
  --color-black-05: rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

body {
  overflow: hidden;
  color: var(--color-white);
  background: var(--color-black);
  text-rendering: optimizeSpeed;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
  text-decoration: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button {
  border: none;
  cursor: pointer;
  padding: 0.75rem;
  user-select: none;
  font-weight: bold;
  font-size: 15px;
  color: var(--color-black);
  background: var(--color-white);
  border-radius: var(--border-radius);
}

h1 {
  font-weight: 800;
  font-size: 2.625rem;
}

h2 {
  font-weight: 900;
  font-size: 2rem;
}

b {
  font-weight: 900;
  font-size: 15px;
}

input {
  width: 100%;
  height: 42px;
  font-size: 15px;
  padding: 12px 14px;
  border: 2px solid var(--color-light-background);
  color: var(--color-white);
  background: var(--color-light-background);
  border-radius: var(--border-radius);
}

input:focus {
  border: 2px solid var(--color-white);
  outline: none;
}

@media (max-width: 875px) {
  body {
    overflow-y: auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}